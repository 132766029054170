import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/post-layout.js";
import { HashLink } from '../components/link';
import externalLinkIcons from '../images/external_link_icons.png';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Accessible web links`}</h1>
    <p>{`Let’s take a dive into using links in our web applications.  `}</p>
    <h2><HashLink id="wcag" to="/link-a11y#wcag" mdxType="HashLink">{`WCAG requirements`}</HashLink></h2>
    <p>{`The Web Content Accessibility Guidelines detail that we need to make sure the purpose of each link can be determined.`}</p>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/WCAG21/#link-purpose-in-context"
      }}>{`success criterion 2.4.4 for Level A compliance`}</a>{` regarding link purpose in context:`}</p>
    <blockquote>
      <p parentName="blockquote">{`The purpose of each link can be determined from the link text alone or from the link text together with its programmatically determined link context, except where the purpose of the link would be ambiguous to users in general.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`In HTML, information that is `}<strong parentName="p">{`programmatically determinable`}</strong>{` from a link in English includes text that is in the same paragraph, list, or table cell as the link or in a table header cell that is associated with the table cell that contains the link.`}</p>
    </blockquote>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/WCAG21/#link-purpose-link-only"
      }}>{`success criterion 2.4.9 for Level AAA compliance`}</a>{` regarding purpose being evident via only the link itself:`}</p>
    <blockquote>
      <p parentName="blockquote">{`A mechanism is available to allow the purpose of each link to be identified from link text alone, except where the purpose of the link would be ambiguous to users in general.`}</p>
    </blockquote>
    <p>{`Additionally the link needs to be discernible. Link text must have a 3:1 contrast ratio from the surrounding non-link text. The link must also present a “non-color designator” (typically the introduction of the underline) on both mouse hover and keyboard focus. These two requirements help ensure that all users can differentiate non-link text from links, even if they have low vision, color deficiency, or have overridden page colors.`}</p>
    <p>{`Make sure the size of your touch targets are compliant with WCAG standards. Someone with cerebral palsy, for example, may be able to use their hands to manipulate a mouse, but may have difficulty with the precise movements and muscle control necessary to click on a small link.`}</p>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/WCAG21/#target-size"
      }}>{`success criterion 2.5.5 for Level AAA compliance`}</a>{` regarding touch target sizes states:`}</p>
    <blockquote>
      <p parentName="blockquote">{`The size of the`}<a parentName="p" {...{
          "href": "https://www.w3.org/TR/WCAG21/#dfn-target"
        }}>{` target`}</a>{` for`}<a parentName="p" {...{
          "href": "https://www.w3.org/TR/WCAG21/#dfn-pointer-inputs"
        }}>{` pointer inputs`}</a>{` is at least 44 by 44`}<a parentName="p" {...{
          "href": "https://www.w3.org/TR/WCAG21/#dfn-css-pixels"
        }}>{` CSS pixels`}</a>{` except when:`}</p>
      <ul parentName="blockquote">
        <li parentName="ul"><strong parentName="li">{`Equivalent`}</strong>{`: The target is available through an equivalent link or control on the same page that is at least 44 by 44 CSS pixels;`}</li>
        <li parentName="ul"><strong parentName="li">{`Inline`}</strong>{`: The target is in a sentence or block of text;`}</li>
        <li parentName="ul"><strong parentName="li">{`User Agent Control`}</strong>{`: The size of the target is determined by the user agent and is not modified by the author;`}</li>
        <li parentName="ul"><strong parentName="li">{`Essential`}</strong>{`: A particular presentation of the target is`}<a parentName="li" {...{
            "href": "https://www.w3.org/TR/WCAG21/#dfn-essential"
          }}>{` essential`}</a>{` to the information being conveyed.`}</li>
      </ul>
    </blockquote>
    <h2><HashLink id="anchor" to="/link-a11y#anchor" mdxType="HashLink">{`Anatomy of an anchor tag`}</HashLink></h2>
    <p>{`HTML anchor elements must have a non-empty `}<inlineCode parentName="p">{`href`}</inlineCode>{` attribute in order to be considered true links and to be accessible to keyboard users.`}</p>
    <p>{`Some sites use a link with “#” as the `}<inlineCode parentName="p">{`href`}</inlineCode>{` value which opens a dropdown of more links on hover or click. This isn’t ideal. Instead we should create a page where everyone can navigate to by clicking the link in order to access the same sub links available in the dropdown.`}</p>
    <p>{`When images are used as links, the alternative text performs the function of link text. The alternative text should convey the content of the image and the function of the link. If a linked image does not have alternative text, a screen reader may read the image file name or the URL being linked to.`}</p>
    <h2><HashLink id="clarity" to="/link-a11y#clarity" mdxType="HashLink">{`Link content clarity`}</HashLink></h2>
    <p>{`URLs are not always human-readable or screen-reader friendly. Many URLs contain combinations of numbers, letters, ampersands, dashes, underscores, and other characters that make sense to scripts and databases but which make little or no sense to the average person. In most cases, it is better to use human-readable text instead of the URL. If the URL is relatively short (such as a site's homepage), the URL is usually fine to be used as the link text. `}</p>
    <p>{`Short, concise links are less likely to frustrate screen reader users than long, imprecise links. However, links are more useful when they make sense out of context. Authors should avoid non-informative link phrases such as “click here”.`}</p>
    <p>{`Nearly any time `}<inlineCode parentName="p">{`a:hover`}</inlineCode>{` (or other hover effects) are defined in CSS, it should be modified to be `}<inlineCode parentName="p">{`a:hover, a:focus`}</inlineCode>{` to ensure the same visual presentation is available when keyboard users navigate or “tab” to the link.`}</p>
    <p>{`In-page links should generally be presented so it is visually and/or contextually apparent that they are in-page links.`}</p>
    <p>{`When using a screen reader, it can sometimes be a little difficult to tell when one link ends and where an adjacent link begins. JAWS says "link" before each link, which minimizes this problem, but it can be a little more difficult with other screen readers. One solution is to provide a non-link character between each link. The vertical bar ( | ) is used quite often for this purpose. Another solution is to put the links in an ordered or numbered list. Screen readers tend to pause between list items, helping the user audibly distinguish between separate links.`}</p>
    <p>{`Authors can designate links that open in new tabs/windows by placing this information in parentheses at the end of the link, for example by saying "Biographical sketch (opens new window)." or by using an appropriate image within the link text.`}</p>
    <div style={{
      textAlign: 'center'
    }}>
    <img src={externalLinkIcons} alt="List of icons that designate an external link" />
    </div>
    <p>{`Place the distinguishing information of links at the beginning of a link. Don't put extra information first. For example, don't use a link with display text, "Link opens in a new window: Products." Instead, use a link with display text, "Products (opens in a new window)".`}</p>
    <p>{`Users should generally be alerted to links that lead to non-HTML resources, such as PDF files, Word files, PowerPoint files, and so on. When identifying the link file type, this additional content should be presented inside the link, rather than just after it, so that the information is presented with the link if a screen reader user navigates by links or reads a link list.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`
<!-- use -->

<a href="1099.pdf">Tax form 1099 (PDF)</a>

<!-- rather than -->

<a href="1099.pdf">Tax form 1099</a> (PDF)

`}</code></pre>
    <h2><HashLink id="conclusion" to="/link-a11y#conclusion" mdxType="HashLink">{`If you walk away with nothing else`}</HashLink></h2>
    <p>{`Please try to think of every possible user when including links in your content. `}</p>
    <p>{`Let’s attempt to understand and follow the Web Content Accessibility Guidelines when building our applications. Something so simple like adding a link correctly can have an extremely positive effect on the user experience of your app.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      